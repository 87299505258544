import React, {Component} from 'react'
import axios from 'axios'
import {Helmet} from 'react-helmet'

import ProjectList from '../components/Projects/ProjectList'
import ProjectsData from '../data/db.json'

const Projects = () => {
    return (
        <>
            <Helmet>
                <title>Proyectos | Páginas y Sitios Web en los que he participado</title>
                <meta name="description" content="Iván López Luna | Desarrollador VTEX Front-End" />
            </Helmet>
            <ProjectList ProjectsData={ProjectsData} />
        </>
    )
}

export default Projects