import React from 'react'
import './Characters.scss'
import ApolloClient, { gql } from 'apollo-boost'
import { ApolloProvider, Query } from 'react-apollo'

const client = new ApolloClient({
    uri: 'https://rickandmortyapi.com/graphql'
})

const CharactersQuery = (props) => {
    return <Query query={gql`
        {
            characters{
                results{
                    id
                    name
                    image
                    species
                }
            }
        }
    `}>
        {props.children}
    </Query>
}

const Characters = () => (
    <ApolloProvider client={client}>
        <div className="CharacterList">
            <CharactersQuery>
            {({loading, error, data}) => {
                if (loading) return <p>Loading...</p>
                if (error) return <p>Error!</p>

                return data.characters.results.map(character => {
                    return <div key={character.id} className="CharacterCard product-item-wrapper">
                            <img src={character.image} className="CharacterCard-Image" />
                            <h3 className="CharacterCard-Name">{character.name}</h3>
                            <strong className="CharacterCard-Specie">{character.species}</strong>
                        </div>
                })
            }}
            </CharactersQuery>
        </div>
    </ApolloProvider>
)

export default Characters