import React, {useState, useEffect} from 'react'

import ProjectList from './ProjectList'
import ProjectsData from '../../data/db.json'

const ProjectsHome = () => {
    const [suffleData, setSuffleData] = useState([])
    const [suffleDataN, setSuffleDataN] = useState([])


    useEffect(() => {
        setSuffleData(ProjectsData.slice().sort( () => Math.random() - 0.5 ))

    }, [])

    useEffect(() => {
      setSuffleDataN(suffleData.slice(0, 4))

    }, [suffleData])


    console.log(`%c ProjectsData`, 'color: Gold', {ProjectsData, suffleData, suffleDataN})

    return (
        <ProjectList ProjectsData={true ? suffleDataN : ProjectsData} />
    )
}

export default ProjectsHome