import React from 'react'
import ProjectCard from './ProjectCard'
import withLoader from '../HOC/withLoader'
import {Helmet} from 'react-helmet'

const ProjectList = ({ProjectsData}) => (
    <>
    <div id="section-book" className="re-cien box-reset">
        <div className="product-list grid-view grid-small" data-height="parent">
            {
                ProjectsData.map((project, index) => (
                    <ProjectCard
                        key={project.id}
                        id={project.id}
                        name={project.name}
                        link={project.link}
                        kind={project.kind}
                        summary={project.summary}
                        image={project.image}
                        internalLink={project.internalLink}
                        agencyName={project.agencyName}
                        agencyLink={project.agencyLink}
                    />
                ))
            }
            {/* <ProjectCard
                name="Individual"
                // link="https://www.dorothygaynor.com"
                // kind="eCommerce"
                summary="Maquetación • Implementación"
                // agency="Vicom"
                other="Test"
            /> */}
        </div>
    </div>
    </>
)

export default withLoader('ProjectsData')(ProjectList)