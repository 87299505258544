import React, { useState, useEffect } from 'react'
import {Helmet} from 'react-helmet'
import axios from 'axios'

import ProjectsData from '../../data/db.json'

const ProjectDetail = ({match, location, history}) => {
    //
    const [stateRe, setStateRe] = useState({})

    useEffect(() => {
    // Get Project ID
    const   projectID           = location.search.replace('?id=',''),
            projectInternalLink = location.pathname.replace('/proyectos/','')

      setStateRe(ProjectsData.filter( project => project.internalLink.replace(/\s/g, '-') === projectInternalLink )[0])

    }, [])


    // console.log(match, location, history)
    console.log(stateRe)
    return (
        <main className="Main--ProjectDetail">
            <div className="re-central">
            {
                stateRe
                ?
                <>
                    <Helmet>
                        <title>{`${stateRe.name} / Proyecto web - ${stateRe.kind}`}</title>
                        <meta name="description" content={`${stateRe.name} | ${stateRe.kind} | ${stateRe.summary}`} />
                    </Helmet>
                    <div className="ProjectDetail">
                        <div className="ProjectDetail-Gallery">
                            <img src={ stateRe.image } alt={`Iván López Luna [Front-End Developer & UI / UX Designer] | ${stateRe.name} - ${stateRe.kind}`} />
                        </div>
                        <div className="ProjectDetail-Info">
                            <h1 className="ProjectDetail-Name tag-title--level-1">
                                <em>{ stateRe.name }</em>
                                <small>[{stateRe.kind}]</small>
                            </h1>
                            <div className="ProjectDetail-Summary"><strong>{ stateRe.summary }</strong></div><br/>
                            <div className="ProjectDetail-Agency font-10 font-pastel-gray">
                                Agencia. <a href={ stateRe.agencyLink} target="_blank" className="pastel-gray" rel="nofollow">{stateRe.agencyName}</a> / <a href={ stateRe.link } target="_blank" className="link secondary" rel="nofollow">Go to the site!</a>
                            </div>
                        </div>
                    </div>
                </>
                : <h1>This project doesn't exist</h1>
            }
            </div>
        </main>
    )
}

export default ProjectDetail