import React, { Component } from 'react'

const withLoader = (propsName) => (WrappedComponent) => {
    return class WithLoader extends Component {
        constructor(props){
            super(props)
        }

        render() {
            return this.props[propsName].length === 0
            ? <h2 className="text-center">Loading...</h2>
            : <WrappedComponent {...this.props} />
        }
    }
}

export default withLoader