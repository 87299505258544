import React from 'react'
import {Helmet} from 'react-helmet'

const MyInfo = () => (
    <main className="Main--AboutMe">
        <Helmet>
            <title>Iván López Luna | Desarrollador VTEX Front-End</title>
        </Helmet>
        <section className="re-central text-center AboutMe">
            <img src="https://ivan.visionario.mx/images/layout/bullet.png" alt="RE" id="water-back" className="signo" />
            <h1 className="tag-title--level-2 AboutMe-Name">Iván López Luna</h1>
            <small className="tag-title--level-3 AboutMe-Role">Front-End Developer &amp; <span>UI / UX Designer</span></small>
            <address className="AboutMe-Contact">
                <a href="tel:+522221356937">222 135 6937</a> <br/>
                <a href="mailto:ivan@visionario.mx">ivan@visionario.mx</a>
            </address>
        </section>
    </main>
)

export default MyInfo