import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

// const ProjectCard = (props) => (
const ProjectCard = ({id, name, link, kind, summary, image, internalLink, agencyName, agencyLink}) => (
    <div className="product-item-wrapper" data-height="siblings">
        <div className="product-item">
            <figure className="product-item-figure">
                <a href={ link } target="_blank" rel="noopener noreferrer nofollow" className="contenedor-img">
                    <img src={ image } alt={`Iván López Luna | Front-End Developer | ${name} - ${kind}`} className="img-centrada" />
                </a>
                <figcaption className="product-item-caption">
                    <h3 className="product-item-name">{ name }</h3>
                    <h2 className="product-item-kind">{ kind ? `${kind}` : ''}</h2>
                    <div className="product-item-summary">{ summary }</div>
                    <div className="product-item-agency">
                        <span className="product-item-agency-label">Agencia: </span>
                        <span className="product-item-agency-name">{ agencyName }</span>
                    </div>
                </figcaption>
            </figure>
            <div className="product-item-action">
                <Link to={ `/proyectos/${internalLink.replace(/\s/g,'-')}` } className="button-lineal secondary">More!</Link>
                <a href={ link } target="_blank" rel="noopener noreferrer nofollow" className="link light-gray">Go to the site</a>
            </div>
        </div>
    </div>
)

ProjectCard.propTypes = {
    name: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string,
    kind: PropTypes.string,
    summary: PropTypes.string,
    agency: PropTypes.string
}

ProjectCard.defaultProps = {
    name: "Without name",
    image: "https://picsum.photos/seed/picsum/560/420?grayscale&blur=1"
}

export default ProjectCard