import React, {Component} from 'react'

class Footer extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: ""
        }
    }

    render() {
        return(
            <footer id="section-contact">
                <div className="principal-footer">
                    <div className="re-central">
                        <address>
                            <h6 className="footer-title">{`Hi${this.state.name}`}!</h6>
                            <em>{this.props.phone}</em><br />
                            <a href="mailto:ivan@visionario.mx">{this.props.mail}</a>
                            {/* <img src="https://ivan.visionario.mx/images/layout/bullet.png" alt="RE" id="water-back" className="signo" /> */}
                        </address>
                    </div>
                </div>
            </footer>
        )
    }

    componentDidMount(){
        let element = document.getElementById('section-contact')
        console.log(element)
    }
}

export default Footer