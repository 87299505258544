import React, {Fragment} from 'react';
import Header from './components/Layout/Header'
import ProjectDetail from './components/Projects/ProjectDetail'
import Characters from './components/Characters/Characters'
import Form from './components/Contact/Form'
import MyInfo from './components/Contact/MyInfo'
import Footer from './components/Layout/Footer'

import Home from './pages/Home'
import Projects from './pages/Projects'

import './scss/styles.scss'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

const App = () => (
    <Router>
        <Header />
        <Switch>
            <Route path="/" exact component={ () => (
                <Fragment>
                    <main>
                        <Home />
                    </main>
                </Fragment>
            )} />
            <Route path="/proyectos/:name" component={ ProjectDetail } />
            <Route path="/proyectos" component={ Projects } />
            <Route path="/characters" component={ Characters } />
            <Route path="/contacto" component={ MyInfo } />
            <Route path="/contact" component={ () => <Form phone="222 135 6937" mail="hi@visionario.mx" /> } />
            <Route component={() => (
                <div className="re-central">
                    <h1>404 - Not found</h1>
                </div>
            )} />
        </Switch>
        <Footer phone="222 135 6937" mail="ivan@visionario.mx" />
    </Router>
)

export default App;
