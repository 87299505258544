import React from 'react'
import {NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet'

import ProjectsHome from '../components/Projects/ProjectsHome'

class Home extends React.Component {
    render(){
        return(
            <>
                <Helmet>
                    <title>Iván López Luna | Front-End &amp; VTEX eCommerce Developer | UI / UX Designer</title>
                </Helmet>
                <main className="Main--AboutMe">
                    <section className="re-central text-center AboutMe">
                        <h1 className="tag-title--level-3 font-black AboutMe-Role">Front-End Developer &amp; <span>UI / UX Designer</span></h1>
                        <div className="AboutMe-Resume">
                            <p>Front-End Developer especializado en <strong>implementación VTEX eCommerce</strong>, con experiencia y aptitudes en <strong>liderazgo de equipos</strong>, así como habilidades a nivel de UI / UX.</p>
                            <p>He participado en proyectos de diferentes magnitudes, en los que contribuyo bajo diferentes roles y etapas que abarcan la <strong>planeación, ejecución, seguimiento y liberación de desarrollos integrales</strong>, así como en <strong>mejoras, actualizaciones e incorporaciones de nuevas funcionalidades</strong> a proyectos existentes.</p>
                            <p>Continuamente busco metodologías, alternativas y tendencias que permitan conseguir mejores resultados en los proyectos, además de una evolución constante del equipo de desarrollo.</p>
                        </div>
                    </section>

                    <section className="re-cien text-center ProjectList--Featured">
                        <h2 className="tag-title--level-2">Algunos proyectos</h2>
                        <ProjectsHome />
                        <NavLink to="/proyectos" className="button-flat secondary">Más proyectos</NavLink>
                    </section>
                </main>
            </>
        )
    }
}

export default Home