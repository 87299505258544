import React from 'react'
import {NavLink} from 'react-router-dom'

const Header = () => (
    <header className="site-header">
        <div className="re-central site-header__content">
            <NavLink to="/" exact className="brand" activeClassName="is-active">
                <em>Iván <span>López Luna</span></em>
                <small>Front-End &amp; VTEX eCommerce Developer</small>
            </NavLink>

            <nav className="site-nav menu-onepage" data-onepage-animation-speed="750">
                <div className="re-nav-color">
                    <div id="dl-menu" className="dl-menuwrapper">
                        <ul className="re-ul-principal dl-menu">
                            <li>
                                <NavLink to="/proyectos" activeClassName="is-active">Proyectos</NavLink>
                            </li>
                            <li className="font-secondary">•</li>
                            <li>
                                <NavLink to="/contacto" activeClassName="is-active">Contacto</NavLink>
                            </li>
                            {/* <li className="menu-onepage-link"><a href="/contact">Contacto</a></li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </header>
)

export default Header