import React, {Component} from 'react'

class Form extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: "",
            mail: "",
            date: new Date()
        }
        this.updateName = this.updateName.bind(this)
        this.updateMail = this.updateMail.bind(this)
        this.updateDate = this.updateDate.bind(this)
    }
    // Methods
    updateName(e){
        this.setState({
            name: e.target.value
        })
    }
    updateMail(e){
        this.setState({
            mail: e.target.value
        })
    }
    updateDate(){
        this.setState({
            date: new Date()
        })
    }

    render() {
        return(
            <div className="re-central">
                <form id="form">
                    <h3>Who are you? <small>{Math.ceil(this.state.date / 1000)}</small></h3>
                    <div className="control-wrapper float-left">
                        <label>Name</label>
                        <input type="text"
                            onChange={this.updateName}
                        />
                    </div>
                    <div className="control-wrapper float-right">
                        <label>e-mail</label>
                        <input type="email"
                            onChange={this.updateMail}
                        />
                    </div>
                </form>

                <h6>{`Hola ${this.state.name}!`} {`${this.state.mail}`}</h6>

                <address>
                    <em>{this.props.phone}</em><br />
                    <a href="mailto:ivan@visionario.mx">{this.props.mail}</a>
                </address>
            </div>
        )
    }
    //
    componentDidMount(){
        let element = document.getElementById('form')
        console.log(element)

        this.intervalDate = setInterval(() => {
            this.updateDate()
        }, 1000)
    }
    //
    componentDidUpdate(prevProps, prevState){
        console.log(prevProps, prevState)
    }
    //
    componentWillUnmount(){
        clearInterval(this.intervalDate)
    }
}

export default Form